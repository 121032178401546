<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-inline-block"><i class="nav-icon icon-layers"></i> Invoices </h4><router-link class="btn btn-success float-right" :to="{ name: 'Vendor Create Preset'}"><i class="fa fa-plus"></i> Create Preset</router-link>
          <hr>
          <div class="row my-3 mb-1">
            <div class="col-md-4 mb-3">
              <h5>Select Company</h5>
              <v-select :clearable="false"
                class="project-select"
                v-model="companyid"
                label="cn"
                :options="companyData"
                :value="companyid"
                placeholder="Search For Company"
                @input="getProjects()"
              ></v-select>
            </div>
            <div class="col-9 col-md-4" v-if="companyid">
              <h5 v-if="companyid">Select Project</h5>
              <v-select :clearable="false"
                class="project-select"
                v-model="projectid"
                label="projectname"
                :options="projectData"
                :value="projectid"
                placeholder="Search For Project"
                @input="loadInvoices()"
              ></v-select>
            </div>
            <div v-if="projectid" class="col-3 col-md-4">
              <h5>&nbsp;</h5>
              <button @click="loadInvoices()" class="btn btn-primary"><i class="fa fa-sync-alt"></i></button>
            </div>
          </div>
          <v-client-table class="vendor-table table-responsive" :columns="columns" :data="invoiceData" :options="options" :theme="theme" id="dataTable">
            <span slot="edit" slot-scope="props">
              <router-link class="btn btn-primary mr-1 btn-sm" v-on:click.native="setPreset(props.row)" :to="{ name: 'Vendor Edit Preset', params: { invoice: props.row }}"><i class="fa fa-eye"></i></router-link>
              <!-- <button class="btn btn-danger btn-sm" style="padding:5px 9px;" @click="deletePreset(props.row.itid)"><i class="fa fa-times-circle"></i></button> -->
            </span>
             <span slot="delete" slot-scope="props">
              <button class="btn btn-danger btn-sm" style="padding:5px 9px;" @click="deletePreset(props.row.itid)"><i class="fa fa-trash"></i></button>
            </span>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import InvoiceHistory from './../invoice/InvoiceHistory'

Vue.use(ClientTable)

export default {
  name: 'Vendor',
  components: {
    ClientTable,
    InvoiceHistory,
  },
  data () {
    return {
      columns: ['it_name', 'project_name', 'ptn', 'edit','delete'],
      invoiceData: [],
      companyData: [],
      history: [],
      project: [],
      projectid: '',
      companyid: '',
      projectData: [],
      companyData: [],
      options: {
        headings: {
          it_name: 'Preset',
          project_name: 'Project Name',
          ptn: 'Project Number',
          edit: 'Review Preset',
          delete: 'Delete Preset'
        },
        sortable: ['it_name', 'project_name', 'ptn'],
        filterable: ['it_name', 'project_name', 'ptn'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false
    }
  },
  mounted: function () {
    this.$http.get('/vendor/get/allowed_companies')
    .then(response => {
      this.companyData = response.data.result;
    })

    if(this.$session.get('p-company') && this.$session.get('p-project')){
      this.companyid = this.$session.get('p-company');
      this.projectid = this.$session.get('p-project');
      this.loadInvoices();
    }
  },
  methods: {
    deletePreset(id) {
      if (confirm("Are you sure you want to delete this preset?")) {
        let data = JSON.stringify({
          id: id,
          companyid: this.companyid.companyid,
          projectid: this.projectid.projectid
        })
        this.$http.post('/vendor/delete/invoice/template', data)
        .then(response => {
          this.loadInvoices();
        })
      }
    },
    loadInvoices() {
      this.$session.set('p-company', this.companyid)
      this.$session.set('p-project', this.projectid)

      this.$http.get('/vendor/get/invoice/templates/' + this.companyid.companyid + "/" + this.projectid.projectid)
      .then(response => {
        this.invoiceData = response.data.result;
      })
    },
    getProjects(){
      console.log('get_projects');
      this.$http.get('/vendor/get/allowed_projects/' + this.companyid.companyid + '/' + '?search=true')
      .then(response => {
        this.projectData = response.data.result;
      })
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    sendHistory(history) {
      this.history = history;
    },
  },
}
</script>

<style lang="scss">
.vendor-table {
  overflow-x: hidden !important;
}
@media screen and (max-width: 767px){
  .vendor-table {
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7){
      display: none !important;
    }
    th:nth-child(4){
      display: table-cell !important;
    }
    td:nth-child(4){
      display: table-cell !important;
    }
    td, th{
      width: 50%;
    }
  }
}
@media screen and (max-width: 580px){
  .vendor-table {
    td, th{
      width: 50%;
    }
  }
}
</style>
